
body {
    font-family: futura-pt, roboto;
    -webkit-font-smoothing: antialiased;
}

svg {
    overflow: inherit;
    vertical-align: initial;
}

.h-100vh{
  height: 100vh !important
}

// card

.bg-darkest {
    background-color: #000000;
    background-size: cover;
    color: #fff;
    &:hover{
        color: #ccc;
    }
}

.bg-lightest {
    background-color: #FFFFFF;
}
.display-block {
    display: block;
}
.height-full {
    height: 100%;
}
.scene {
    position: relative;
}
.scene-content {
    position: relative;
    z-index: 1;
}

.theme-light .type-tag {
    color: rgba(255,255,255,0.7);
}
.bg-lightest {
    background-color: #FFFFFF;
}

div.overlay, div.hero-overlay:before, div.hero-overlay-dark:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.8);
}

.hero-overlay{
    height: 100vh;
    background-size: cover;
    max-height: 375px;
}

.hero-overlay:before {
    opacity: .8;
    display: block;
    content: "";
    z-index: 1;
}

.type-display2 {
    font-size: 44px;
    line-height: 1;
}

.text-brake {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}



.caption, .type-caption, .cover-overline {
    font-size: 0.7rem;
    font-weight: 700;
    margin-bottom: 8px;
}


.inlineTooltip {
    button{
        outline: none;
    }
}

.public-DraftStyleDefault-ul, .public-DraftStyleDefault-ol {
    margin: 18px 21px;
    padding: 0;
}



graf--pre, .public-DraftStyleDefault-pre {
    background: black !important;
    font-family: Menlo, Monaco, Consolas, "Courier New", "Courier", monospace;
    font-size: 16px;
    margin-bottom: 20px;
    padding: 20px;
    white-space: pre-wrap;
    color: white !important;
    pre {
        color: white !important;
    }
}

.markup--mixtapeEmbed-anchor{
    &:hover{
        color: #a09f9f;
        text-decoration: none;        
    }

}

.graf--media-embed-close{
    &:hover{
        cursor:pointer;
        text-decoration: none;
        color: #a09f9f;

    }
    
}

.graf{
    margin-left: 0px !important;
}

.jumbotron {
    background-color: transparent !important;
}

#app{
  margin-top: 0px !important;
}

.container-fluid {
    width: 100%;
    padding-right: 0px !important; 
    padding-left: 0px !important; 
    margin-right: auto;
    margin-left: auto;
}

#main-nav{
    /*border-bottom: 1px solid rgb(232, 234, 237);;*/
    /*border-bottom: 2px solid rgb(85, 86, 88);*/
    border-bottom: 1px solid #dadcde;
}

.main-sidebar {
  border-right: 1px solid #dadcde;
  //background: #eeeceb;
  padding-right: 0px !important;
}

.main-sidebar a {
    //color: #fff; //#b1ffea;
}

.main-sidebar a:hover{
    //color: #6e982a;
}

#main-nav{
    background: #fff;
}

.main-content {
  padding-left: 0px !important;
  background-color: #f5f6f7;
}

.Sidebar .pagehead {

}
.pagehead {
    zoom: 1;
    margin: 0 auto;
    padding: 0;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    box-shadow: 0 3px 2px -2px rgba(200,200,200,.15);
}

.Sidebar .pagehead .menu {
    width: 100%;
}
.pagehead .menu {
    list-style: none;
    zoom: 1;
    margin: 0;
    padding: 0;
    text-align: center;
}

.pagehead .menu li {
    display: inline-block;
}

.Summary {

}

.Summary .SummaryActions {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.Summary .Tree>.TreeEntry {
    margin: 5px 0;
}

.Tree>.TreeEntry.is-active>.inner {
    background: #3884ff;
    border-left-color: #3884ff;
    color: #fff;
    font-weight: 500;
}

.Tree>.TreeEntry>.inner {
    padding: 6px 5px;
    cursor: default;
    display: block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    position: relative;
    border-left: 3px solid transparent;
}

.Tree>.TreeEntry>.inner .name {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;

}

.Summary .Tree>.TreeEntry>.inner .name .SpanInput {
    border: 1px solid transparent;
}

.Tree>.TreeEntry>.inner .name .SpanInput, .Tree>.TreeEntry>.inner .name input {

}

.Summary .Tree>.TreeEntry>.inner .name .SummaryEditButton {
    order: 3;
    cursor: pointer;
    display: none;
    font-size: 14px;
    height: 18px;
    width: 18px;
    text-align: center;
    line-height: 18px;
    opacity: .4;
}

.Tree>.TreeEntry>.inner .name i {
    margin-right: 5px;
    line-height: 20px;
}

.SpanInput {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Sidebar .Sidebar-Body {
    overflow-y: auto;
    padding: 10px;
}

.Sidebar .pagehead .menu>li.active a {
    padding-bottom: 11px;
    border-width: 1px;
}

.Sidebar .pagehead .menu>li a {
    padding-bottom: 12px;
    padding-top: 12px;
}
.pagehead .menu li:first-child>a {
    margin-left: 0;
}
.pagehead .menu li.active>a {
    padding-bottom: 17px;
    border-width: 3px;
    color: #4a4e57;
    border-color: #0bc392;
    font-weight: 700;
}
.pagehead .menu li>a {
    color: #8c919b;
    padding: 19px 15px;
    margin-bottom: -1px;
    border-bottom: 1px solid #e5e5e5;
    letter-spacing: -.02em;
    font-weight: 500;
    font-style: normal;
    font-size: 11px;
    letter-spacing: .2em;
    text-transform: uppercase;
    display: inline-block;
}


.Workspace {
    width: 100%;
    height: 100%;
}

.ScrollArea {
    width: 100%;
    height: 100%;
    /*overflow: auto;*/
}

.EditorContainer {
    background: #fafafa;
    /*padding-top: 20px;
    padding-bottom: 20px;*/
}

.DocumentEditor.fontSize-default {
    font-size: 14px;
}
.DocumentEditor {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    -o-user-select: text;
    user-select: text;
    /*max-width: 900px;
    min-height: 1056px;*/
    margin-left: auto!important;
    margin-right: auto!important;
    background: #fff;
    /*border: 1px solid #e8eaed;*/
    /*border-radius: 2px;*/
    padding: 50px;
    cursor: text;
    position: relative;
}

.DocumentEditor-FrontMatter-Container {
    padding-bottom: 30px;
}

.DocumentEditor-FrontMatter-Container .DocumentEditor-FrontMatter {
    position: relative;
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -30px;
}

.DocumentEditor-FrontMatter-Container .DocumentEditor-FrontMatter .toggler {
    position: absolute;
    padding: 2px 7px;
    top: 0;
    right: 0;
    z-index: 1;
    background: #fafafa;
    font-size: 12px;
    cursor: pointer;
    border-bottom: 1px solid #e8eaed;
    border-left: 1px solid #e8eaed;
    color: #737373;
    border-bottom-left-radius: 3px;
}




.react-contextmenu {
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 16px;
    color: #373a3c;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    outline: none;
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
}

.react-contextmenu-item {
    padding: 3px 20px;
    font-weight: 400;
    line-height: 1.5;
    color: #373a3c;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
    cursor: pointer;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
    color: #878a8c;
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item--divider {
    margin-bottom: 3px;
    padding: 2px 0;
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
}
.react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: "▶";
    display: inline-block;
    position: absolute;
    right: 7px;
}

.example-multiple-targets::after {
    content: attr(data-count);
    display: block;
}





/// APEX STYLE DOC

:root {
    --ease: cubic-bezier(.82, 0, .12, 1);
    --width: 800px;
    --header-height: 400px;
    --tracking: 0.05rem;
    --tracking-medium: 0.5rem;
    --tracking-large: 0.8rem;
    --dark: #000;
    --blue: #33A9FF;
    --light-gray: #fafafa;
    --bg: #fff;
    --fg: #868E96;
    --fg-dark: #212529;
    --grape: #5f3dc4;
    --selection-bg: var(--blue);
    --selection-fg: white;
}

.Wrapper {
    display: flex;
    justify-content: center;
}

.Container {
    width: 800px; /*var(--width);*/
    display: flex;
    flex-direction: column;
}

.Header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px; /*var(--header-height);*/
}

.Title.center {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 500px;
}
.Title {
    margin: 5px 0;
    line-height: 2.2em;
}

.Title .text {
    letter-spacing: 0.05rem; /*var(--tracking);*/
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
}

.Title > span {
    display: block;
}

.Title .subtext {
    color: #fafafa ; /*var(--fg-color-light);*/
    font-size: 0.8rem;
    text-transform: uppercase;
    display: none;
}

.Content-wrapper {
    display: flex;
}

.Sidebar {
    flex: 1 1 auto;
}

.Menu {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    color: #212529; /*var(--fg-dark);*/
}

.Menu > .item {
    padding-bottom: 15px;
}

.Menu > .item > a.active {
    color: #212529; /*var(--fg-dark);*/
}

.Menu > .item > a {
    position: relative;
    user-select: none;
    font-weight: 400;
    transition: color 200ms;
    color: #868E96; /*var(--fg);*/
}

.Menu > .item > a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -5px;
    left: 0;
    background-color: #212529; /*var(--fg-dark);*/
    visibility: hidden;
    transform: scaleX(0);
    transform-origin: left center;
    transition: all 250ms cubic-bezier(.82, 0, .12, 1); /*var(--ease);*/
}

.Content {
    width: 75%;
}

.Footer {
    height: 100px;
}

.Page:first-child {
    margin-top: 0;
    padding-top: 0;
}

.Page {
    margin-top: 100px;
    padding-top: 50px;
}


// recording button

.recordingButton{
   animation:myfirst .6s;
    -moz-animation:myfirst .6s infinite; /* Firefox */
    -webkit-animation:myfirst .6s infinite; /* Safari and Chrome */

    @-moz-keyframes myfirst /* Firefox */
      {
      0%   {background:red;}
      50%  {background:#ff5e5e;}
      100% {background:red;}
    }
   
    @-webkit-keyframes myfirst /* Firefox */
      {
      0%   {background:red;}
      50%  {background:#ff5e5e;}
      100%   {background:red;}
    }    
}





// MASORNY

.dante-giphy-wrapper{
    padding: 10px;
    border: 1px solid #ccc;
    position: relative;
    .graf--media-embed-close{
        position: absolute;
        right: 30px;
        top: 10px;
    }
}

.giphy-logo {
    img {
        float: right;
    }
}

.masorny-wrapper{
    //height: 300px;
    //overflow: scroll;
}

.masonry--h, .masonry--v {
    margin-left: -8px;
    counter-reset: brick;
}
.masonry--h {
    flex-flow: row wrap;
}
.masonry {
    display: flex;
    width: 100%;
}

@media only screen and (min-width: 1024px) {
  /* Horizontal masonry bricks on desktop-sized screen */
  .masonry-brick--h:nth-child(4n+1) {
    width: 50px;
  }
  .masonry-brick--h:nth-child(4n+2) {
    width: 25px;
  }
  .masonry-brick--h:nth-child(4n+3) {
    width: 80px;
  }
  .masonry-brick--h:nth-child(4n+4) {
    width: 120px;
  }

  /* Adjusting vertical masonry height on desktop-sized screen */
  .masonry--v {
    max-height: 1600px;
  }

  /* Vertical masonry bricks on desktop-sized screen */
  .masonry-brick--v {
    width: 33.33333%;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  /* Horizontal masonry bricks on tabled-sized screen */
  .masonry-brick--h:nth-child(4n+1) {
    width: 20px;
  }
  .masonry-brick--h:nth-child(4n+2) {
    width: 50px;
  }
  .masonry-brick--h:nth-child(4n+3) {
    width: 20px;
  }
  .masonry-brick--h:nth-child(4n+4) {
    width: 80px;
  }

  /* Adjusting vertical masonry height on tablet-sized screen */
  .masonry--v {
    max-height: 2000px;
  }

  /* Vertical masonry bricks on tablet-sized screen */
  .masonry-brick--v {
    width: 50%;
  }
}

.masonry-brick--h {
    flex: auto;
    height: 94px;
    min-width: 150px;
}

.masonry-brick {
    overflow: hidden;
    border-radius: 5px;
    margin: 0 0 8px 8px;
    background-color: #333;
    color: white;
    position: relative;
}

.masonry-brick:hover:after {
  font-size: 2.25em;
  opacity: 1;
}

.masonry-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: brightness(50%);
    transition: filter .25s, opacity .25s ease-in-out;
    &:hover{
        filter: brightness(80%);
    }
}

.masonry-brick:after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5000;
    transform: translate(-50%, -50%);
    counter-increment: brick;
    content: counter(brick);
    transition: font-size .25s, opacity .25s ease-in-out;
    font-weight: 700;
    opacity: .2;
    font-size: 1.25em;
}

