body{
  -webkit-font-smoothing: antialiased;
}

header h1 {
  padding-left: 0px;
  font-size: .9em;
  font-weight: 100;
  line-height: 1.2;
  display: inline-block;
  width: 225px;
  padding-bottom: 20px;
}

.anchor {
    float: left;
    padding-right: 4px;
    margin-left: -20px;
}

header.bookHeader {
  padding: 10px;
}

@media only screen and (min-width: 768px) {
  

  header.bookHeader {
    padding: 80px;
  }

  header h1 {
      width: 350px;
      padding-left: 0;
      font-size: 1.3em;
  }  
}

.chapter blockquote p, header h1 {
  //  font-family: FuturaPT;
}

body .chapter figure:after, 
body .toc .background, 
body .toc header {
    background-color: #FFF166;
}

body .toc {
    position: absolute;
    top: 0;
    left: 0;
    width: 102%;
    z-index: 200;
    &.is-expanded{
      height: 100vh;
    }
    
}

@media only screen and (min-width: 768px){
  body .toc {
      display: block;
      //position: static;
      border-left: 0;
  }  
}

@media only screen and (min-width: 768px){
  body .toc header {
    -moz-transition-duration: .25s;
    -o-transition-duration: .25s;
    -webkit-transition-duration: .25s;
    transition-duration: .25s;
    -moz-transition-property: background-color;
    -o-transition-property: background-color;
    -webkit-transition-property: background-color;
    transition-property: background-color;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    width: 80px;
    height: 100%;
    background-color: #eaeaeb;
    cursor: pointer;
}  
}

@media only screen and (min-width: 1280px) and (min-width: 768px){
  header ul.Booknav {
    padding: 80px;
  }  
}

@media only screen and (min-width: 768px){
  header ul.Booknav {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    padding: 60px;
    text-align: right;
  }  
}

header ul.Booknav {
   // display: none;
   display:flex;
}

header ul.Booknav li {
    display: inline;
}


@media only screen and (min-width: 1280px){
  header ul.Booknav a {
    font-size: 1em;
  }  
}

header ul.Booknav a {
    position: relative;
    margin-left: 40px;
    color: #121010;
    font-size: .9em;
    font-weight: 500;
    text-decoration: none;
}

body .toc header h2 {
  display: none;
}

@media only screen and (min-width: 768px){

  body .toc header h2 {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }  
}


body.is-section-beginning .toc header h2 span, 
body.is-section-finishing .toc header h2 span, 
body.is-section-progressing .toc header h2 span {
    color: rgba(18,16,16,.25);
}

@media only screen and (min-width: 1280px) and (min-width: 768px){

  body .toc header h2 span {
      margin-top: -10px;
      font-size: 1em;
  }  
}

@media only screen and (min-width: 768px){
  body .toc header h2 span {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    -moz-transition-duration: .25s;
    -o-transition-duration: .25s;
    -webkit-transition-duration: .25s;
    transition-duration: .25s;
    -moz-transition-property: color;
    -o-transition-property: color;
    -webkit-transition-property: color;
    transition-property: color;
    display: block;
    position: absolute;
    left: -200px;
    top: 50%;
    width: 400px;
    margin-left: 40px;
    margin-top: -20px;
    color: #c4c3c3;
    font-size: .4em;
    font-weight: 700;
    vertical-align: middle;
    text-align: center;
}  
}

.content-wrapper{
  margin-left: 22px;
  width: 90%; 
}



@media only screen and (min-width: 768px){
  .content-wrapper{
    margin-left: 96px;
  }  
}


.content {
 width: auto;
 padding: 36px
}

@media only screen and (min-width: 768px){
  .content{
    width: 700px;
    margin: 0 auto;
    padding:0px;
  }  
}

body .toc .wrapper {
    display: none;
    padding: 30px;
}

.toc ul > li {
    margin-left: 10px;
}

@media only screen and (min-width: 768px){
  body .toc .wrapper {
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      padding: 0;
      overflow-y: auto;
  }  
}

@media only screen and (min-width: 768px){
  body .toc header .icon, body .toc header svg {
      top: 60px;
      left: 30px;
  }  
}

body .toc header .icon, body .toc header svg {
    position: absolute;
    top: 35px;
    right: 30px;
    z-index: 200;
    cursor: pointer;
}
body .toc header .icon {
    width: 20px;
    height: 20px;
}


.toc.is-section-beginning .background {
    //background-color: #F3A293;
    background-color: #FFF166;
}


.toc .background {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    //transition-duration: .25s;
    //transition-property: background-color;
    //-webkit-transition: -webkit-transform 2s ease-in-out;
    position: absolute;
    top: 0;
    left: -1500;
    z-index: -1;
    width: 100%;
    height: 100vh;
}

@media only screen and (min-width: 768px){
  .toc .background {
      display: none;
      position: fixed;
      z-index: 10;
  }  
}


body .toc h3 {
    margin-bottom: 10px;
    color: rgba(18,16,16,.5);
}

@media only screen and (min-width: 768px){
  body .toc h3 {
      margin-bottom: 20px;
  }  
}

@media only screen and (min-width: 768px){
body .toc h3, body .toc p {
      font-size: .8em;
  }  
}


body .toc .chapters, body .toc .pages, body .toc .sections {
    margin-bottom: 30px;
    margin-left: 10px;
}

@media only screen and (min-width: 1280px){
  body .toc .chapters.chapters.chapters, body .toc .chapters.sections.chapters, body .toc .pages.chapters.chapters, body .toc .pages.sections.chapters, body .toc .sections.chapters.chapters, body .toc .sections.sections.chapters {
    left: 480px;
}
}

@media only screen and (min-width: 768px){
  body .toc .chapters.chapters.chapters, body .toc .chapters.sections.chapters, body .toc .pages.chapters.chapters, body .toc .pages.sections.chapters, body .toc .sections.chapters.chapters, body .toc .sections.sections.chapters {
    left: 480px;
}
}

@media only screen and (min-width: 1280px){
  body .toc .chapters.chapters, body .toc .chapters.sections, body .toc .pages.chapters, body .toc .pages.sections, body .toc .sections.chapters, body .toc .sections.sections {
    top: 80px;
    padding-bottom: 80px;
}
}

@media only screen and (min-width: 768px){
  body .toc .chapters.chapters, body .toc .chapters.sections, body .toc .pages.chapters, body .toc .pages.sections, body .toc .sections.chapters, body .toc .sections.sections {
    position: absolute;
    top: 60px;
    padding-bottom: 60px;
}
}

body .toc .chapters.chapters, body .toc .pages.chapters, body .toc .sections.chapters {
    margin-bottom: 0;
}

@media only screen and (min-width: 768px){
  body .toc .chapters, body .toc .pages, body .toc .sections {
    margin-bottom: 0;
}
}



@media only screen and (min-width: 1280px){
body .toc .chapters.chapters.sections, body .toc .chapters.sections.sections, body .toc .pages.chapters.sections, body .toc .pages.sections.sections, body .toc .sections.chapters.sections, body .toc .sections.sections.sections {
    left: 160px;
}}
@media only screen and (min-width: 768px){
body .toc .chapters.chapters.sections, body .toc .chapters.sections.sections, body .toc .pages.chapters.sections, body .toc .pages.sections.sections, body .toc .sections.chapters.sections, body .toc .sections.sections.sections {
    left: 140px;
}}
@media only screen and (min-width: 1280px){
body .toc .chapters.chapters, body .toc .chapters.sections, body .toc .pages.chapters, body .toc .pages.sections, body .toc .sections.chapters, body .toc .sections.sections {
    top: 80px;
    padding-bottom: 80px;
}}
@media only screen and (min-width: 768px){
body .toc .chapters.chapters, body .toc .chapters.sections, body .toc .pages.chapters, body .toc .pages.sections, body .toc .sections.chapters, body .toc .sections.sections {
    position: absolute;
    top: 60px;
    padding-bottom: 60px;
}}
@media only screen and (min-width: 768px){
body .toc .chapters, body .toc .pages, body .toc .sections {
    margin-bottom: 0;
}}



body .toc ul {
  margin: 0px;
  padding: 0px;
}

body .toc ul li {
    margin-bottom: 6px;
    font-size: .8em;
    list-style: none
}

body .toc ul ul li {
    margin-left: 21px;
    
}

@media only screen and (min-width: 1280px){
  body .toc ul li {
      margin-bottom: 20px;
      font-size: 1.2em;
  }
}

@media only screen and (min-width: 768px){
  body .toc ul li {
    margin-bottom: 14px;
    //font-size: 1em;
}
}

body .toc a {
    transition-duration: .25s;
    -moz-transition-property: color;
    -o-transition-property: color;
    -webkit-transition-property: color;
    transition-property: color;
    position: relative;
    color: #121010;
    font-weight: 500;
    text-decoration: none;
}



.carousel {
    position: relative;
    margin: 0 auto;
    padding: 0 30px;
    margin-bottom: 109px;
}

@media only screen and (min-width: 1280px){
  .carousel {
      max-width: 990px;
  }  
}

@media only screen and (min-width: 1024px){
  .carousel {
      padding-top: 60px;
      max-width: 904px;
  }  
}

@media only screen and (min-width: 768px){
  .carousel {
      padding: 40px 60px;
  }  
}

.carousel a.next {
    position: absolute;
    top: 0;
    right: 30px;
    text-align: right;
}
.carousel a {
    display: inline-block;
    vertical-align: middle;
    width: 45%;
    position: relative;
    text-decoration: none;
}


@media only screen and (min-width: 1024px){
  .carousel a.next {
      top: 60px;
  }  
}

@media only screen and (min-width: 768px){
  .carousel a.next {
      top: 40px;
      right: 60px;
  }  
}


@media only screen and (min-width: 768px){
  .carousel a {
      font-size: 1.2em;
  }  
}

.carousel a, .home h2 a {
    color: #121010;
    font-weight: 500;
}

.carousel a.previous svg {
    margin-right: 10px;
}

.carousel a.next svg {
    margin-left: 10px;
}

@media only screen and (min-width: 768px){
.carousel svg {
    width: 12px;
    height: 22px;
}  
}

.carousel svg {
    display: inline-block;
    width: 9px;
    height: 16px;
    margin-top: -2px;
    vertical-align: middle;
}

.carousel a .label {
    display: inline-block;
    vertical-align: middle;
}

.carousel a .title {
    display: none;
}

.carousel a .label, .carousel a .title {
    vertical-align: middle;
    position: relative;
    //display: block;
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}


@media only screen and (min-width: 1024px){
  .carousel a .label, .carousel a .title {
      width: 90%;
  }  
}

@media only screen and (min-width: 1024px){
  .carousel a .label {
    display: none;
  }  
}


@media only screen and (min-width: 1024px){
.carousel a .title {
    display: inline-block;
    vertical-align: middle;
}  
}

@media only screen and (min-width: 1024px){
.carousel a .label, .carousel a .title {
    width: 90%;
}  
}


@media only screen and (min-width: 1280px){
  body>footer {
      padding: 80px;
  }  
}

@media only screen and (min-width: 768px){
  body>footer {
      padding: 60px;
  }  
}

/*footer {
  position: relative;
  padding: 30px;
  margin-top: 130px;
}*/


footer a.hallmark {
    position: fixed;
    bottom: 30px;
    right: 30px;
    margin-right: 0;
    color: #ccc;
}


  @media screen and (min-width: 320px) and (max-width: 480px) {
  footer a.hallmark  {
    transform: rotate(-90deg);
    right: -40px;
    bottom: 50vh;
  }  
  } 



footer a {
    margin-right: 20px;
    font-size: .9em;
    font-weight: 500;
}
footer a, footer a svg {
    display: inline-block;
    vertical-align: middle;
}
footer a, header h1 a {
    color: #121010;
    text-decoration: none;
}


@media only screen and (min-width: 1280px){
  body>footer a.hallmark {
    top: 80px;
    right: 80px;
}
}

@media only screen and (min-width: 768px){
  body>footer a.hallmark {
    bottom: 60px;
    right: 60px;
}  
}










